.loader {
  transform: rotate(-90deg);
  stroke-linecap: round;
  stroke-width: 4;
  fill: none;

  .internal-circle,
  .external-circle {
    stroke-dashoffset: 0;
    transform-origin: center;
    opacity: 0.8;
  }

  .internal-circle {
    stroke: var(--red-marianne-main-472);
    stroke-dasharray: 187;
    animation: internal 1s ease-in-out infinite;
  }

  .external-circle {
    stroke: var(--blue-france-113);
    stroke-dasharray: 312;
    animation: external 1s linear infinite;
  }

  @keyframes internal {
    0% {
      stroke-dashoffset: 187;
    }

    25% {
      stroke-dashoffset: 80;
    }

    100% {
      stroke-dashoffset: 187;
      transform: rotate(360deg);
    }
  }

  @keyframes external {
    0% {
      stroke-dashoffset: 312;
      transform: rotate(70deg);
    }

    60% {
      stroke-dashoffset: -312;
    }

    100% {
      stroke-dashoffset: -312;
      transform: rotate(450deg);
    }
  }
}