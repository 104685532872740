body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ul-styled {
  list-style-type: disc !important;

  >li {
    list-style: inherit;
    margin-bottom: 0;
  }

  padding-left: 1rem !important;
  margin-bottom: 1rem;
}

.stick {
  position: sticky;
  top: 0;
  background: var(--background-default-grey);
  z-index: 100;
  margin: 0;
  margin-top: 0px;
  margin-left: 0px;
  margin-top: .25rem;
}

.stick+* {
  margin-bottom: 1.5rem;
}

.fr-breadcrumb {
  margin-bottom: 1rem;
}

.fr-breadcrumb__item--current {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media only screen and (min-width: 768px) {
    max-width: 30rem;
  }

  max-width: 15rem;
}

.fr-icon-theme-fill::before {
  mask-image: url('../public/icons/system/arrow-right-s-line.svg') !important;
  -webkit-mask-image: url('../public/icons/system/arrow-right-s-line.svg') !important;
}

:root {
  --campus-france-color: #2f71a0;
  --CCI-color: #3e3e3E;
  --embassy-color: #cac426;
}

.home-title {
  font-weight: normal;
  text-align: center;
  color: #000091 !important;
}

.not-bold {
  font-weight: normal;
}

.d-inline {
  display: inline;
}

.text-center {
  text-align: center;
}

.is-bold {
  font-weight: 800;
}

.extra-large-text {
  font-size: 1.7rem;
}

.text-right {
  text-align: right;
}

.fr-card-container {
  position: relative;

  .badge-container {
    position: absolute;
    top: -0.1rem;
    left: -1.2rem;
    padding: 0.5rem;
  }
}

.w-100 {
  width: 100% !important;
}

.campusFrance-border-card {
  border-bottom: 3px solid var(--campus-france-color);
}

.cci-border-card {
  border-bottom: 3px solid var(--CCI-color);
}

.embassy-border-card {
  border-bottom: 3px solid var(--embassy-color);
}

.overlay {
  background: var(--text-title-grey);
  background: linear-gradient(to bottom, var(--text-title-grey) 0%, var(--text-title-grey) 60%, rgba(255, 255, 255, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.highcharts-background {
  fill: var(--background-default-grey);
}

.highcharts-xaxis-labels,
.highcharts-yaxis-labels text {
  stroke: var(--text-default-grey) !important;
}

.highcharts-legend-item text {
  fill: var(--text-default-grey) !important;
}

.highcharts-title,
.highcharts-subtitle {
  fill: var(--text-default-grey) !important;
  font-family: 'Marianne' !important;
}

// accessibilité
.skipLinks-a11y {
  position: absolute;
  left: -1000px;
  top: -1000px;
}