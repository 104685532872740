.form {
  width: 100%;
}
.searchbar {
  flex-flow: row 1;
  width: 100%;
}
.label {
  color: var(--text-label-grey);
  display: block;
  font-size: 1rem;
  line-height: 1.5rem;
}
.autocomplete {
  position: relative;
  --idle: #0000;
  --hover: var(--background-default-grey-hover);
  --active: var(--background-default-grey-active);
  --ol-type: none;
  --ul-type: none;
  --ul-start: 0;
  --ol-start: 0;
  --xl-block: 0;
  --li-bottom: 0;
  --ol-content: none;
  --underline-img: none;
  --text-spacing: 0;
  --title-spacing: 0;
  .loader {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: .5rem 0;
  }
  .list {
    // margin-top: 0.25rem;
    border-radius: 0 0 .5rem .5rem;
    background-color: var(--background-default-grey);
    box-shadow: 0 6px 18px 0 var(--border-default-grey);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: calc(var(--ground) + 1000);
    .hovered {
      background-color: var(--hover);
    }
    .item {
      // border-bottom: 1px solid grey;
      // &:last-child {
      //   border-bottom: none;
      // }
      .btn {
        &:hover{
          background-color: inherit;
        }
        padding: .5rem .5rem;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: flex-start;
        .content {
          text-align: start;
          padding: 0 1rem;
          flex-grow: 1;
          overflow: hidden;
          // max-height: 100px;
          white-space: nowrap;
          text-overflow: ellipsis;
          // margin-right: auto;
        }
      }
    }
  }

}

.badge {
  position: absolute;
  margin: 0.375rem;
  background-color: var(--hover);
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}
.badgeselected {
  background-color: var(--active);
  padding-right: 1.5rem;
  &:after {
    position: absolute;
    top: 0;
    right: .25rem;
    content: "\eb99";
    font-size: 1rem;
    line-height: 1.5rem;
    background-color: inherit;
    box-shadow: none;
    font-family: remixicon;
  }
}